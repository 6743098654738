.header-link {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: currentColor;
  text-decoration: none;
  font-variant: small-caps;
}

.markdown {
  font-size: 1.4em;
  margin: 1em;
}

.markdown code {
  font-family: Consolas, "courier new", monospace;
  background-color: #222;
  border-radius: 8px;
  padding: 5px;
  line-height: 1.8;
}

.markdown a {
  color: inherit;
}

.markdown table,
th,
td {
  border: 1px solid black;
}
